<template>
  <jc-form
    :model="form"
    :rules="rules"
    ref="form"
    :label-width="labelWidth"
    label-position="left"
    class="jc-form-tiny-width"
  >
    <jc-form-item prop="organizersName" label="主办单位名称：">
      <jc-input
        v-model.trim="form.organizersName"
        type="text"
        placeholder="请输入主办单位名称"
        @change="organizersNameChange"
      ></jc-input>
    </jc-form-item>
    <!-- <jc-form-item prop="investorName" label="投资者或单位主管：" v-if="isShowField('investorName')">
      <jc-input
        v-model.trim="form.investorName"
        type="text"
        placeholder="请输入投资者或单位主管"
        :disabled="role !== 5"
      ></jc-input>
    </jc-form-item> -->
    <jc-form-item v-if="isShowField('indentityAddress')" prop="indentityAddress" label="证件地址：">
      <!-- <dz-input v-model.trim="form.indentityAddress" type="text" placeholder="请输入证件地址" dzType="address"></dz-input> -->
      <jc-input
        v-model.trim="form.indentityAddress"
        type="text"
        placeholder="请输入证件地址"
        dzType="address"
      ></jc-input>
    </jc-form-item>
    <jc-form-item v-if="isShowField('detailAddress')" prop="detailAddress" label="通信地址：">
      <p>
        {{ `${infoData.provinceName}${infoData.cityName}${infoData.countyName}` }}
      </p>
      <jc-input
        v-model.trim="form.detailAddress"
        type="text"
        placeholder="请输入实际通信地址，必须精确到门牌号"
      ></jc-input>
      <p slot="hint">请将通信地址补充完整，通信地址需详细至门牌号</p>
    </jc-form-item>
  </jc-form>
</template>
<script>
import { PRINCIPAL_COMMUNITY_RULE } from '@/views/service/rules'
import { AGREEMENT } from '@/views/service/constant'
export default {
  data() {
    return {
      form: {
        organizersName: '', // 主办单位
        investorName: '', // 投资者或单位主管：
        indentityAddress: '', // 证件地址
        detailAddress: '', // 通信地址
        miitRecordPwd: ''
      },
      addressTip: true,
      AGREEMENT,
      rules: PRINCIPAL_COMMUNITY_RULE.call(this)
    }
  },
  props: {
    infoData: {
      type: Object
    },
    labelWidth: String
  },
  components: {},
  mixins: [],
  methods: {
    organizersNameChange(v) {
      this.form = {
        ...this.form,
        investorName: v
      }
      console.log('organizersNameChange', v, this.form.investorName)
    },
    getInit() {
      try {
        this.form.organizersName = this.infoData.organizersName || ''
        this.form.investorName = this.infoData.investorName || ''
        this.form.indentityAddress = this.infoData.indentityAddress || ''
        this.form.detailAddress = this.infoData.detailAddress || ''
        this.form.miitRecordPwd = this.infoData.miitRecordPwd
      } catch (err) {
        console.error(err)
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve(this.form)
          } else {
            reject(new Error('submit false'))
          }
        })
      })
    }
  },
  mounted() {
    this.getInit()
  },
  created() {},
  watch: {
    infoData() {
      this.getInit()
    }
  },
  computed: {},
  filters: {}
}
</script>
